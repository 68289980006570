import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { ButtonGray, ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { useHistory } from 'react-router'
import axios from 'axios'
import { CHECK_USA_LOCATION } from '../../constants'

const LPFWarningPopup = ({ setShowConfirm }: any) => {
  const [showUsPopup, setShowUsPopup] = useState(false)

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const checkUsaLocation = async () => {
      try {
        const result = await axios.get(CHECK_USA_LOCATION)
        if (result?.data?.data?.isRestrictedCountry) {
          setShowUsPopup(true)
          setShowConfirm({ alertType: 'MAINTENANCE', hideClose: true, message: 'Region Restricted' })
        }
      } catch (e) {
        console.log(e)
      }
    }

    checkUsaLocation()
  }, [])

  return (
    <>
      <Modal isOpen={showUsPopup} maxHeight={100}>
        <div className="w-full pt-[48px] pb-[36px] px-[30px]">
          <div className="flex justify-center mb-[25px]">
            <img src={'/images/danger.svg'} className="w-[51px]" alt="danger" />
          </div>

          <h2 className="text-[20px] font-semibold text-white text-center mb-[25px]">Important Notice</h2>
          <p className="text-[18px] leading-[24px] text-white mb-[25px] text-center">
            Based on your IP address, we currently do not provide services in your country or region due to local laws,
            regulations, or policies. <br /> <br />
            We apologize for any inconvenience this may cause. Please stay informed about and comply with the rules
            applicable in your location regarding the use of decentralized exchanges.
          </p>

          <p className="text-[18px] text-white mb-[25px] text-center">
            View{' '}
            <a
              className="link !underline"
              target="_blank"
              rel="noreferrer"
              href="https://vgxfoundation.com/terms-conditions"
            >
              Terms of Use
            </a>
          </p>

          {/* <ButtonPrimary
            onClick={() => {
              setShowUsPopup(false)
            }}
          >
            Dismiss
          </ButtonPrimary> */}
        </div>
      </Modal>

      <Modal isOpen={showConfirmPopup} maxHeight={100}>
        <div className="w-full pt-[48px] pb-[36px] px-[30px]">
          <div className="flex justify-center mb-[25px]">
            <img src={'/images/danger.svg'} className="w-[58px]" alt="danger" />
          </div>

          <h2 className="text-[20px] font-semibold text-white text-center mb-[25px]">Important Notice</h2>
          <p className="text-[18px] leading-[24px] text-white text-center mb-[35px]">
            Do you confirm you are not a US citizen?
          </p>

          <div className="flex items-center">
            <ButtonGray
              onClick={() => {
                history.push('/swap')
              }}
              marginRight={'8px'}
            >
              No
            </ButtonGray>
            <ButtonPrimary
              onClick={() => {
                localStorage.setItem('IS_CONFIRM_LOCATION', 'true')
                setShowConfirmPopup(false)
              }}
              marginLeft={'8px'}
            >
              Yes
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LPFWarningPopup
